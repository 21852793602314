.App {
  background-image: url(./assets/top-left-bg.png), url(./assets/bottom-right-bg.png), url(./assets/india-map.png);
  background-size: 75vh, 75vh, 75vh;
  background-position: top left, bottom right, center;
  background-repeat: no-repeat;
  padding: 30px 0;
  background-attachment: fixed;
}

.h2 {
  text-decoration: underline;
  margin-right: 40%;
  margin-left: 40%;
  width: 20%;
  background-color: black;
  height: 1px;
  justify-content: center;
  align-items: center;
  align-self: center;
  display: flex;
}

.body {
  background-color: green;
}

.tag {
  text-align: center;
}

.slick-slide img {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo1 {
  height: 100px;
  margin-bottom: 10pt;
}



.logoMainView {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  display: flex;
}

.contentView {
  justify-content: center;
  align-items: center;
  background-color: #61dafb;
  flex-direction: row;
  display: flex;
}

.contentText {
  display: flex;
  background-color: blue;
  justify-content: left;
  align-items: flex-start;
  text-align: left;
}

.Android-logo {
  height: 30px;
  width: 30px;
  cursor: pointer;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.appView {}

.appView p {
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 5px
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.image-row {
  display: flex;
  justify-content: center;
  /* Centers the images horizontally */
}

.image-container {
  margin: 0 10px;
  /* Adjust spacing between images */
}

.image-container img {
  max-width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.info-tag {
  text-align: center;
}

.info-tag h5:last-of-type {
  font-weight: 400;
}

.info-tag h5:last-of-type a {
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

.bg-logo {
  background-image: url(./assets/bgImage.jpg);
  /* Replace 'your-image-url.jpg' with the path to your image */
  background-size: cover;
  background-position: center;
  width: 100vw;
  /* 100% of the viewport width */
  height: 100vh;
  /* 100% of the viewport height */
}

.img {
  background-size: contain;
  width: 100%;
  height: 100%;
  background-color: bisque;
  border-radius: 10px;
}

.card-text {
  color: black;
  font-weight: bold;
}

.card-header-text {
  color: black;
  font-weight: bold;
}

.card-text1 {
  color: black;
  font-weight: 400;
  font-size: 22px;
  text-align: justify;
}

.section-title {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.tabs .tab {
  padding: 10px;
  cursor: pointer;
  width: calc(25% - 6px);
  flex: 0 0 auto;
  text-align: center;
  background: #dedede;
  font-size: 12px;
  font-weight: 500;
  min-height: 130px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 2px rgb(34 34 34 / 22%);
}

.tabs .tab:before {
  content: '';
  height: 50%;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, #000000, transparent);
  z-index: 1;
}

.tabs .tab>img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  filter: grayscale(1);
  object-fit: cover;
  top: 0;
  transition: 300ms all;
}

.tabs .tab p {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  color: #fff;
  transition: 300ms all;
}

.tabs .tab:hover p {
  padding-bottom: 8px;
}

.tabs .tab.active {
  background: #fcb05e;
}

.tabs .tab.active img,
.tabs .tab:hover img {
  filter: grayscale(0);
}

.tab-content {
  padding-top: 60px;
}

.tab-content h2 {
  font-weight: 800;
  font-size: 30px;
}

.tab-content p {
  font-size: 22px;
  text-align: justify;
}

.category-tab .slick-slide img {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 2px rgb(34 34 34 / 22%);
  height: 450px;
  width: 100%;
  object-fit: cover;
}

.select-dropdown {
  margin-bottom: 20px;
}

.select-dropdown select {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 1px rgb(34 34 34 / 22%);
}

.select-dropdown select:focus {
  outline: none;
}

.footer-links a {
  font-size: 16px;
  font-weight: 500;
  padding: 0 8px;
  position: relative;
  color: #212529;
  text-decoration: none;
}

.footer-links a:not(:first-child):before {
  content: '';
  width: 1px;
  position: absolute;
  display: inline-block;
  height: 15px;
  background: rgb(33 37 41 / 75%);
  left: 0;
  top: 50%;
  margin-top: 1px;
  transform: translateY(-50%);
}

.pageHeading h1 {
  font-weight: 700;
  font-size: 40px;
}

.pageHeading h2 {
  font-weight: 800;
  font-size: 30px;
  color: #043cb9;
}

.pageHeading p {
  font-weight: 500;
}

ol {
  counter-reset: item;
  padding-left: 0 !important;
}

ol ol{
  padding-left: 2rem !important;
}

ol > li {
  display: block
}

ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-weight: 700;
}

ul{
  list-style: disc;
}

li{
  padding-bottom: 10px;
}

li p{
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .App {
    background-size: 50vh;
  }

  .card-text1,
  .tab-content p {
    font-size: 16px;
  }

  .section-title, .pageHeading h1 {
    font-size: 26px;
  }

  .tab-content h2, .pageHeading h2 {
    font-size: 20px;
  }

  .tab-content {
    padding-top: 40px;
  }

  .h5,
  h5,
  .footer-links a {
    font-size: 14px !important;
  }

  .tabs .tab {
    width: calc(50% - 4px);
  }
}

@media (max-width: 575px) {
  .category-tab .slick-slide img {
    height: 280px;
  }

  .App-logo1 {
    height: 80px;
  }

  ol ol{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  ol img{
    width: 100%;
  }
}