body {
  margin: 0;
  background-image: url(./assets/bg-texture.png);
  background-attachment: fixed;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*btn-primary class override */
.btn-primary {
  background-color: #30419b;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.btn-div {
  display: flex;
  flex-direction: row;
  padding: 50px 50px;
}

.appStyle {
  text-transform: uppercase;
  display: flex;
  margin: 20px;
  
}
.historicalDataDiv {
  justify-content: center;
  align-items: center;
  text-align: left;
}

.player-wrapper {
  perspective: 600px;
}

.player-wrapper video{
  transform: rotateY(344deg) rotate(10deg) scale(0.8);
  height: 440px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  padding:  20;
  /* background-color: rebeccapurple; */
}
.p-4{
  background-color: #30419b;
}
.m-2{
  justify-content: center;
  align-items: center;
}
.card{
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.card-list{
  flex-direction: row;
}